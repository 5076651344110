@font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 100;
    src: url('SF-UI-Display-Ultralight.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 200;
    src: url('SF-UI-Display-Thin.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 300;
    src: url('SF-UI-Display-Light.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 400;
    src: url('SF-UI-Display-Regular.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 500;
    src: url('SF-UI-Display-Medium.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 600;
    src: url('SF-UI-Display-Semibold.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 700;
    src: url('SF-UI-Display-Bold.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 800;
    src: url('SF-UI-Display-Heavy.woff') format('woff');
}

@font-face {
    font-family: 'SF UI Display';
    font-style: normal;
    font-weight: 900;
    src: url('SF-UI-Display-Black.woff') format('woff');
}