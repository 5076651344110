.sidemenu > .nav > .nav-link {
  margin-bottom: 1rem;
  border-radius: 10px;
  height: 79px;
  background-color: #1C1B1E;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  font-weight: 600; }
  .sidemenu > .nav > .nav-link.active {
    border: 1px solid #C67DFB; }

.sidemenu.sidemenu--mobile,
.sidemenu .sidemenu--mobile:hover {
  background-color: #1C1B1E !important;
  border: 1px solid #C67DFB !important;
  height: 60px; }
