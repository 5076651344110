.accordion__title-container {
  @apply tw-inline-flex tw-flex-wrap tw-items-center tw-space-x-9; }

.accordion__caret {
  @apply tw-w-3.5 tw-h-2 tw-text-purple-1; }

.accordion__title {
  @apply tw-flex-1; }

.accordion__panel-text {
  @apply tw-text-base tw-break-all; }
