body {
  @apply tw-font-poppins tw-bg-black tw-text-white; }

#header-menu-mobile {
  @apply tw-fixed tw-transition-all tw-duration-700 tw-z-50 tw-h-full tw-overflow-y-scroll;
  background: #041236;
  width: 300px;
  right: -300px; }
  #header-menu-mobile.active {
    @apply tw-right-0; }

.header-backdrop {
  @apply tw-bg-black tw-fixed tw-inset-0 tw-opacity-0 tw-transition-all tw-duration-700 tw-z-40 tw-pointer-events-none; }
  #header-menu-mobile.active ~ .header-backdrop {
    @apply tw-opacity-50 tw-pointer-events-auto; }

#coming-soon-modal {
  @apply tw-fixed tw-z-50 tw--translate-x-1/2 tw--translate-y-1/2 tw-left-1/2 tw-top-1/2 tw-transform tw-opacity-0 tw-transition-all tw-duration-700 tw-pointer-events-none tw-max-w-xl tw-w-11/12; }
  #coming-soon-modal.active {
    @apply tw-opacity-100 tw-pointer-events-auto; }

.modal-backdrop {
  @apply tw-bg-black tw-fixed tw-inset-0 tw-opacity-0 tw-transition-all tw-duration-700 tw-z-40 tw-pointer-events-none; }
  #coming-soon-modal.active ~ .modal-backdrop {
    @apply tw-opacity-50 tw-pointer-events-auto; }

.react-player {
  @apply tw-relative;
  padding-top: 56.25%;
  /* 720 / 1280 = 0.5625 */ }

.ol-styled {
  list-style-type: decimal;
  margin-left: 1rem; }

.ul-styled {
  list-style-type: disc;
  margin-left: 2rem; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  @apply tw-font-sf-ui-display tw-font-extrabold; }

h1, .h1, .main-heading {
  @apply tw-text-8xl tw-leading-tight; }

h2, .h2, .heading {
  @apply tw-text-6xl; }

h3, .h3 {
  @apply tw-text-5xl; }

h4, .h4 {
  @apply tw-text-3xl; }

p {
  @apply tw-text-xl; }

@media (max-width: 1024px) {
  h1, .h1, .main-heading {
    @apply tw-text-6xl; }
  h2, .h2, .heading {
    @apply tw-text-4xl; }
  h3, .h3 {
    @apply tw-text-2xl; }
  h4, .h4 {
    @apply tw-text-xl; }
  p {
    @apply tw-text-lg; } }

@media (max-width: 768px) {
  h1, .h1, .main-heading {
    @apply tw-text-5xl; }
  h2, .h2, .heading {
    @apply tw-text-3xl; }
  h3, .h3 {
    @apply tw-text-xl; }
  p {
    @apply tw-text-base; } }

.circle {
  @apply tw-w-5 tw-h-5 tw-rounded-full; }
  .circle--xl {
    @apply tw-w-52 tw-h-52; }

.btn-2 {
  @apply tw-font-medium tw-px-5 tw-py-4 tw-rounded-4xl tw-text-white;
  background: #000; }

.btn-2--md {
  width: 170px;
  max-width: 100%; }

.btn-2--lg {
  width: 220px;
  max-width: 100%; }

.btn-2--xl {
  width: 290px;
  max-width: 100%; }

.btn-2--gradient {
  background: linear-gradient(180deg, #CB0FF0 0%, #402BDC 100%); }

.btn-2--blue {
  @apply tw-bg-blue-2; }

.btn-2--green {
  @apply tw-bg-green-1; }

.btn-2--red {
  @apply tw-bg-red-1; }

.btn-2--turquoise {
  @apply tw-bg-turquoise-1; }

.btn-2--yellow {
  @apply tw-bg-yellow-1; }

@media (max-width: 1024px) {
  .btn-2 {
    @apply tw-text-sm tw-px-4 tw-py-3; } }

.accordion__title {
  @apply tw-cursor-pointer tw-inline-block; }

.accordion__panel {
  @apply tw-h-0 tw-opacity-0 tw-pl-16 tw-transition-all tw-duration-300 tw-invisible; }
  .accordion__panel.accordion__panel--active {
    @apply tw-h-full tw-opacity-100 tw-visible; }

.tabs__tabpane {
  @apply tw-mb-4 lg:tw-mb-8 xl:tw-mb-12 tw-pb-4 lg:tw-pb-6 tw-px-8 tw-opacity-30 tw-transition-all tw-duration-700 tw-relative; }
  .tabs__tabpane::after {
    @apply tw-absolute tw-bottom-0 tw-inset-x-0 tw-h-1.5 tw-bg-white tw-opacity-0;
    content: ''; }
  .tabs__tabpane--active {
    @apply tw-opacity-100; }
    .tabs__tabpane--active::after {
      @apply tw-opacity-100; }
  .tabs__tabpane:hover {
    @apply tw-opacity-100; }

.tabs__content {
  @apply tw-opacity-0 tw-h-0 tw-pointer-events-none; }
  .tabs__content--active {
    @apply tw-opacity-100 tw-h-auto tw-pointer-events-auto;
    transition: opacity 0.7s ease-in-out, height 0s ease-in-out 0.7s; }

.dropdown-nav__container {
  background-color: #1C1B1E;
  width: 324px;
  border-radius: 10px;
  margin-top: 20px;
  padding: 24px;
  position: relative;
  inset: 0px auto auto -20px !important; }
  .dropdown-nav__container::before {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    top: -20px;
    right: 20px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #1C1B1E; }
  .dropdown-nav__container.mobile::before {
    display: none; }
  @media (max-width: 1023px) {
    .dropdown-nav__container.mobile {
      flex-direction: column;
      inset: 296px auto auto -140px !important;
      z-index: 100; } }
  @media (max-width: 767px) {
    .dropdown-nav__container.mobile {
      flex-direction: column;
      inset: 117px auto auto -15px !important;
      z-index: 100; } }

.dropdown-nav__items {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Poppins';
  margin-bottom: 24px; }
  .dropdown-nav__items > span:last-of-type {
    opacity: 0.7; }
  .dropdown-nav__items a {
    margin-bottom: 6px;
    cursor: not-allowed; }

.dropdown-nav--mobile {
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  z-index: 100;
  padding: 10px;
  margin: 0 auto;
  background: #1C1B1E;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  max-width: 100%; }
  .dropdown-nav--mobile .nav:first-of-type {
    flex-wrap: nowrap;
    justify-content: space-between; }
  .dropdown-nav--mobile .nav-link {
    color: #fff; }
