.elondoge-list {
  @apply tw-min-h-screen; }
  .elondoge-list__search.form-control {
    background-color: #1C1B1E;
    border: 0;
    box-shadow: 0;
    -webkit-box-shadow: 0;
    @apply tw-text-white; }
    .elondoge-list__search.form-control:focus, .elondoge-list__search.form-control:hover, .elondoge-list__search.form-control:active {
      background-color: #1C1B1E;
      border: 0;
      box-shadow: 0;
      -webkit-box-shadow: 0;
      @apply tw-text-white; }

.elondoge-list__search-prepend.input-group-text {
  background-color: #1C1B1E;
  border: none;
  @apply tw-text-white; }

.elondoge-table__header {
  height: 27px;
  @apply tw-text-white tw-items-center tw-inline-flex; }

.elondoge-table__body {
  background-color: #1C1B1E;
  height: 106px;
  border-radius: 10px;
  @apply tw-text-white tw-items-center tw-inline-flex; }
  @media (max-width: 1023px) {
    .elondoge-table__body {
      height: auto; } }
  .elondoge-table__body:hover {
    border: 1px solid #C67DFB; }
  .elondoge-table__body--active {
    border: 1px solid #C67DFB; }

.elondoge-table__column--detail {
  font-size: 12px; }

.elondoge-table__column--numbers {
  align-items: center;
  height: 43px;
  font-size: 14px;
  @apply tw-inline-flex; }

.elondoge-table__form {
  padding: 1.5rem;
  background-color: #1C1B1E;
  @apply tw-text-white; }
