.account__container {
  background-color: #13192A;
  border-radius: 21.5px;
  padding-left: 21px;
  padding-right: 8px;
  @apply tw-flex tw-items-center; }
  @media (max-width: 767px) {
    .account__container {
      justify-content: start;
      align-items: center; } }

.account__balance span, .account__name span {
  font-size: 14px;
  background: -webkit-linear-gradient(left, #21D0EB, #C936A3);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.account__name {
  background: #1D263F;
  border-radius: 23px;
  padding: 6px 16px;
  margin: 4px auto;
  cursor: pointer; }
  @media (max-width: 767px) {
    .account__name {
      margin: 0;
      padding: 3px 16px; } }

.account-modal__header {
  display: flex;
  flex-direction: column; }

.account-modal__header span {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF; }

.account-modal__header span:last-of-type {
  opacity: 0.3; }

.account-modal__address {
  background: #1C1B1E;
  border-radius: 10px;
  padding: 20px 24px;
  margin-top: 20px; }

.account-modal__links {
  display: flex;
  margin-top: 15px; }

.account-modal__newtab {
  margin-right: 20px; }

.account-modal__links a {
  color: #8667DF !important;
  cursor: pointer; }

.account-modal__newtab img,
.account-modal__clipboard img {
  width: 18px;
  margin-right: 10px; }
