.boost__input > input.form-control {
  background-color: #1C1B1E;
  border: 0;
  box-shadow: 0;
  -webkit-box-shadow: 0;
  @apply tw-text-white; }
  .boost__input > input.form-control:focus, .boost__input > input.form-control:hover, .boost__input > input.form-control:active {
    background-color: #1C1B1E;
    border: 0;
    box-shadow: 0;
    -webkit-box-shadow: 0;
    @apply tw-text-white; }
