#app {
  background-color: #030205 !important;
  font-family: 'Poppins', sans-serif; }

.elondoge-btn {
  border: 1px solid black;
  font-weight: 600;
  padding: 0 1.5rem;
  height: 45px; }
  .elondoge-btn-primary {
    border-radius: 43px;
    background-image: linear-gradient(to bottom right, #D080FF, #6C5DD3);
    @apply tw-text-white; }
    .elondoge-btn-primary:hover, .elondoge-btn-primary:disabled {
      background-image: linear-gradient(to bottom right, #DF6460, #DF6460); }
  .elondoge-btn-dark {
    background: none;
    font-weight: 100;
    @apply tw-text-white; }
  .elondoge-btn-secondary {
    border-radius: 10px;
    background-color: #1C1B1E;
    @apply tw-text-white; }
    .elondoge-btn-secondary:hover, .elondoge-btn-secondary:disabled {
      border-color: #C67DFB; }
  .elondoge-btn-outline-primary {
    border-radius: 43px;
    border: 1px solid #C67DFB;
    color: #C67DFB; }
    .elondoge-btn-outline-primary:hover, .elondoge-btn-outline-primary:disabled {
      border: 1px solid #DF6460;
      color: #DF6460; }
  .elondoge-btn-lg {
    height: 60px; }

.navbar-dark .navbar-nav .nav-link {
  @apply tw-text-white; }

.dropdown-menu {
  background-color: #1C1B1E;
  border-radius: 25px;
  padding: 1rem 1rem; }

.dropdown-item {
  border-radius: 10px;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @apply tw-text-white; }
  .dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active {
    background-color: #8E8D8F;
    @apply tw-text-white; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #1C1B1E; }

.dropdown-menu[data-bs-popper] {
  margin-top: 1rem; }

.modal-content {
  background-color: #030205;
  border: 1px solid #C67DFB;
  border-radius: 30px;
  @apply tw-text-white; }

.modal-header {
  border-bottom: none; }

.btn-close::before {
  font-family: "Font Awesome 5 Free";
  content: "\f00d";
  font-weight: bold;
  @apply tw-text-white tw-font-bold tw-visible; }

.toast-container {
  z-index: 100; }

.navbar-dark .navbar-toggler {
  border-color: #C67DFB; }
  .navbar-dark .navbar-toggler:focus {
    box-shadow: none; }
