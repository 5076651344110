.old-elondoge__btn {
  border-radius: 43px;
  font-weight: 600;
  padding: 0 1.5rem;
  height: 45px; }
  .old-elondoge__btn:hover, .old-elondoge__btn:disabled {
    border: 1px solid #FFFFFF;
    color: #FFFFFF; }
  .old-elondoge__btn--lg {
    height: 60px; }
  .old-elondoge__btn--edoge {
    border: 1px solid #00FFCE;
    color: #00FFCE; }
  .old-elondoge__btn--edao {
    border: 1px solid #FF00FF;
    color: #FF00FF; }
  .old-elondoge__btn--edoge-edao {
    border: 1px solid #E10032;
    color: #E10032; }
    .old-elondoge__btn--edoge-edao:hover {
      --tw-bg-opacity: 1 !important; }

.old-elondoge-table__header {
  height: 27px;
  @apply tw-text-white tw-items-center tw-inline-flex; }

.old-elondoge-table__body {
  background-color: #1C1B1E;
  height: 106px;
  border-radius: 10px;
  @apply tw-text-white tw-items-center tw-inline-flex; }
  @media (max-width: 767px) {
    .old-elondoge-table__body {
      height: auto; } }

.span__edoge {
  color: #00FFCE; }

.span__edao {
  color: #FF00FF; }

.span__edoge-edao {
  color: #E10032; }
